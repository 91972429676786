import React, { useState, useEffect } from "react";
import "../styles/i-styles.css";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import io from "socket.io-client";
import Swal from "sweetalert2";

const socket = io(process.env.REACT_APP_API);

socket.on("connect", () => {
  console.log("Conexión establecida con el servidor Socket.IO");
});

function ImportsTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [term, setTerm] = useState("");
  const [termCreator, setTermCreator] = useState("");
  const [data, setData] = useState([]);
  const [step, setStep] = useState(1);
  const [dataCSV, setDataCSV] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [duplicates, setDuplicates] = useState([]);
  const [importName, setImportName] = useState("");
  const [isLoaded, setIsLoaded] = useState("");
  const [tags, setTags] = useState([]);
  const [dniExistentes, setDniExistentes] = useState([]);
  const [duplicateCount, setDuplicateCount] = useState([]);
  const [invalidRecords, setInvalidRecords] = useState([]);

  useEffect(() => {
    const fetchExistingDNIsFromDatabase = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/audience"
        );
        const existingDNIs = response.data.map((record) => record.dni);
        setDniExistentes(existingDNIs); // Actualizamos el estado con los DNIs existentes
        console.log(existingDNIs);
      } catch (error) {
        console.error("Error al obtener los DNIs existentes:", error);
      }
    };

    fetchExistingDNIsFromDatabase(); // Llamamos a la función al montar el componente
  }, []);

  const fetchExistingDNIsFromDatabase = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API + "/audience"
      );
      const existingDNIs = response.data.map((record) => record.dni);
      setDniExistentes(existingDNIs); // Actualizamos el estado con los DNIs existentes
      console.log(existingDNIs);
    } catch (error) {
      console.error("Error al obtener los DNIs existentes:", error);
    }
  };

  //CHIPS
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const inputText = e.target.value.trim();
      if (inputText) {
        if (inputText.length <= 16) {
          const newTag = `#${inputText}`;
          if (tags.length < 8) {
            setTags((prevTags) => [...prevTags, newTag]);
          }
          e.target.value = "";
        } else {
          toast.error("El máximo de caracteres por etiqueta es de 16.", {
            style: {
              padding: "10px",
              background: "#d34964",
              color: "#fff",
              font: "sans-serif",
            },
            iconTheme: {
              primary: "#d34964",
              secondary: "#FFF",
            },
          });
        }
      }
    }
  };


  const handleRemoveTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  //Gettear empleados API REMOTE

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFileName(file.name);
    const reader = new FileReader();

    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[firstSheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      const validRecords = []; // Para almacenar registros válidos
      const duplicatesSet = new Set(); // Para rastrear DNIs duplicados
      const dniSet = new Set(); // Para rastrear DNIs únicos

      parsedData.forEach((record) => {
        // Recorre las columnas del archivo XLSX y asigna los valores a las columnas de la base de datos
        const dbRecord = {
          name: record.NOMBRE,
          lastname: record.APELLIDO,
          province: record["Cod. Provincia"].toString(),
          email: record.EMAIL1,
          phone: record.TEL1.toString(),
          dni: record.DNI.toString(), // Convierte a cadena
          dob: record["F. DE NAC."].toString(),
          address: record.DOMICILIO1,
          address2: record.DOMICILIO2,
          zipCode: record["COD POSTAL"].toString(),
          location: record.LOCALIDAD,
          emailSyngenta: record.EMAIL2,
          phone2: record.TEL2,
          ingress: record.INGRESO,
          area: record["DESC. AREA"].toString(),
        };

        const email = record.EMAIL1;

        if (!dniSet.has(dbRecord.dni)) {
          dniSet.add(dbRecord.dni);
          validRecords.push(dbRecord);
        } else if (!duplicatesSet.has(dbRecord.dni)) {
          duplicatesSet.add(dbRecord.dni);
        }

        if (dniExistentes.includes(dbRecord.dni)) {
          // Si el DNI existe en la base de datos, se considera duplicado
          duplicatesSet.add(dbRecord.dni);
        }

        if (!email || !email.includes("@")) {
          setInvalidRecords((prevInvalidRecords) => [
            ...prevInvalidRecords,
            record,
          ]);
        }

        console.log("Registros válidos:", validRecords);
      });

      setDuplicates(duplicatesSet);
      setDataCSV(validRecords); // Utilizamos validRecords en lugar de parsedData

      console.log("Registros válidos finales:", validRecords);
      // Agrega este console.log

      const cantidadRegistrosRepetidos = duplicatesSet.size;
      console.log("Registros repetidos:", cantidadRegistrosRepetidos);
      setDuplicateCount(cantidadRegistrosRepetidos);

      console.log(duplicates);
    };

    reader.readAsBinaryString(file);
  };

  const handleUpload = async () => {
    const recordsToSend = [];
    const sentDNIs = new Set(); // Para rastrear los DNIs enviados
    const invalidDNIs = new Set();

    dataCSV.forEach((record) => {
      const dni = record.dni.toString(); // Convertir a cadena

      const normalizedDNIs = dniExistentes.map((existingDNI) =>
        existingDNI.trim()
      );
      const matchingDNIs = normalizedDNIs.filter(
        (existingDNI) => existingDNI === dni.trim()
      );

      if (!sentDNIs.has(dni) && matchingDNIs.length === 0) {
        sentDNIs.add(dni);
        recordsToSend.push(record); // Solo los registros del XLSX, sin importName
      } else {
        invalidDNIs.add(dni); // Agregar DNI inválido al conjunto
      }
    });

    if (recordsToSend.length > 0) {
      try {
        const importData = {
          importName: importName,
          importedRows: recordsToSend.length,
          records: recordsToSend, // Envía los registros del XLSX aquí
        };

        const response = await axios.post(
          process.env.REACT_APP_API + "/insert-audience",
          importData // Envía todos los datos juntos
        );

        console.log("Respuesta del servidor:", response.data);
        toast.success("Registros importados con éxito", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });

        fetchExistingDNIsFromDatabase();
        // Aquí puedes agregar tu lógica de manejo de éxito, como redirigir a otra página, etc.
      } catch (error) {
        console.error("Error al hacer la solicitud:", error);
        toast.error("Verifica tu archivo, hay registros ya existentes");
        toggleModal();
      }
    } else {
      toast.error("No hay registros válidos para subir");
      // Aquí puedes mostrar una notificación o mensaje si no hay registros válidos para subir
    }
  };

  socket.on("server:audienceInserted", (data) => {
    console.log(data);

    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API + "/getImports"
        );
        setData(response.data);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    };

    if (!isLoaded) {
      fetchData();
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API+"/getImports");
        setData(response.data);
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    };

    fetchData();
  }, []);

  const datos = [
    {
      Codigo: "5480",
      Nombre: "New Hires Agosto",
      Audiencia: 25,
      Creador: "Mariano Santos",
      Fecha: "22/8/2022",
    },
    {
      Codigo: "5480",
      Nombre: "New Hires Agosto",
      Audiencia: 25,
      Creador: "Mariano Santos",
      Fecha: "22/8/2022",
    },
    {
      Codigo: "5480",
      Nombre: "New Hires Agosto",
      Audiencia: 25,
      Creador: "Mariano Santos",
      Fecha: "22/8/2022",
    },
    {
      Codigo: "5480",
      Nombre: "New Hires Agosto",
      Audiencia: 25,
      Creador: "Mariano Santos",
      Fecha: "22/8/2022",
    },
    {
      Codigo: "5480",
      Nombre: "New Hires Agosto",
      Audiencia: 25,
      Creador: "Mariano Santos",
      Fecha: "22/8/2022",
    },
  ];

  const handleNextStep = () => {
    if (step === 2) {
      handleUpload();
    }

    setStep(step + 1);
  };

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    setStep(1);
    setDataCSV([]);
    setTags([]);
    setSelectedFileName("");
    setImportName("");
    setDuplicateCount(0);
    setInvalidRecords([]);
  };

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const filteredData = data;

  const totalPages = Math.ceil(datos.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;

  const indexOfFirtsItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirtsItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleClearFilters = () => {
    setTerm("");
    setTermCreator("");
  };

  const eliminarRegistro = (id, importName) => {
    Swal.fire({
      title: `Eliminar a ${importName}`,
      text: "Esta acción no se puede deshacer ¿está Seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // Llamado a la API para eliminar el registro
        axios
          .delete(
            process.env.REACT_APP_API + `/deleteImport/${id}`
          )
          .then(() => {
            // Actualizar la lista de datos
            setData(data.filter((item) => item.id !== id));
            console.log(data);
          })
          .catch((err) => console.log(err));

        // Mostrar una notificación de éxito
        toast.success("Registro eliminado con éxito", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex m-2 mt-4 p-2 justify-content-between align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <h3 className="fs-2">Importaciones</h3>
          <p
            className="bg-primary fs-5 text-white text-center ms-1  mb-0 animate__animated animate__fadeIn"
            style={{ width: "40px" }}
          >
            {currentItems.length}
          </p>
        </div>
        <button
          onClick={toggleModal}
          className="fs-2 bg-primary text-white rounded-circle border-0"
          style={{ height: "50px", width: "50px" }}
        >
          +
        </button>
      </div>
      {modal && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-50 bg-light p-4 rounded animate__animated animate__fadeIn">
              {step === 1 && (
                <div>
                  <h5 className="fw-bold">Nueva Importación</h5>
                  <hr />
                  <div className="d-flex flex-column">
                    <form>
                      <label className="fw-bold mb-2">Nombre</label>
                      <input
                        type="text"
                        className="form-control w-75"
                        name="importName"
                        required
                        value={importName}
                        onChange={(e) => setImportName(e.target.value)}
                      />
                      <label className="fw-bold mb-2">Etiquetas</label>
                      <input
                        type="text"
                        className="form-control w-75"
                        onKeyDown={handleKeyDown}
                      />
                      <div
                        className="mt-2 mb-1 d-flex flex-wrap chips-container"
                        style={{ maxWidth: "500px", maxHeight: "100px" }}
                      >
                        {tags.map((tag, index) => (
                          <span
                            key={index}
                            className="badge rounded-pill me-2 opacity-75 d-flex align-items-center mt-1 mb-1"
                            style={{
                              background: "#4b0082",
                              position: "relative",
                              maxWidth: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {tag}
                            <i
                              className="material-icons ms-1 fw-bold"
                              onClick={() => handleRemoveTag(index)}
                              style={{
                                display: "inline-block",
                                cursor: "pointer",
                                fontSize: "16px",
                              }}
                            >
                              close
                            </i>
                          </span>
                        ))}
                      </div>

                      <label className="fw-bold mb-2">Archivo</label>
                      <div className="d-flex">
                        <input
                          accept=".xlsx"
                          type="file"
                          className="form-control visually-hidden"
                          onChange={handleFileUpload}
                          id="fileInput"
                        />
                        <span className="w-75 form-control">
                          {selectedFileName}
                        </span>
                        <label
                          htmlFor="fileInput"
                          className="btn btn-primary ms-2"
                        >
                          Examinar
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div>
                  <h5 className="fw-bold">Previsualización de Resultados</h5>
                  <hr />
                  <table
                    className="table table-hover shadow-sm p-3  bg-white rounded"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr className="bg-white bg-opacity-75 text-justify">
                        <th scope="col">DNI</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellido</th>
                        <th scope="col">Status</th>
                        <th scope="col">Motivo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataCSV.map((item, i) => {
                        return (
                          <tr className="table text-justify" key={i}>
                            <td>{item.dni}</td>
                            <td>{item.name}</td>
                            <td>{item.lastname}</td>
                            <td>
                              <span>
                                <span
                                  className={
                                    dniExistentes.includes(item.dni) ||
                                    !item.email ||
                                    !item.email.includes("@")
                                      ? "rechazado"
                                      : "aprobado"
                                  }
                                  style={{
                                    backgroundColor:
                                      dniExistentes.includes(item.dni) ||
                                      !item.email ||
                                      !item.email.includes("@")
                                        ? "#eb5f5f"
                                        : "#37c137",
                                    color: "white",
                                    padding: "2px 6px",
                                    borderRadius: "12px",
                                  }}
                                >
                                  {capitalize(
                                    dniExistentes.includes(item.dni) ||
                                      !item.email ||
                                      !item.email.includes("@")
                                      ? "rechazado"
                                      : "aprobado"
                                  )}
                                </span>
                              </span>
                            </td>
                            <td>
                              <span>
                                {dniExistentes.includes(item.dni) ||
                                !item.email ||
                                !item.email.includes("@")
                                  ? dniExistentes.includes(item.dni)
                                    ? "DNI ya existe"
                                    : "Correo no cumple requisitos"
                                  : ""}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div>
                    <p>Registros en Archivo: {dataCSV.length}</p>
                    <p>
                      Registros Inválidos:{" "}
                      {invalidRecords.length > 0 ? invalidRecords.length : 0}
                    </p>
                    <p>Registros duplicados: {duplicates.size} </p>
                    <p>
                      Total a importar:{" "}
                      {dataCSV.length - invalidRecords.length - duplicateCount}
                    </p>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <i className="material-icons fs-1">file_open</i>
                    <h5 className="fw-bold ms-2">¡Importación Exitosa!</h5>
                  </div>

                  <div className="d-flex flex-column align-items-center mt-5">
                    <p>
                      Registros en Archivo:{" "}
                      <span className="fw-bold">{dataCSV.length}</span>
                    </p>
                    <p>
                      Registros invalidos:{" "}
                      <span className="fw-bold">
                        {" "}
                        {invalidRecords.length > 0 ? invalidRecords.length : 0}
                      </span>
                    </p>
                    <p>
                      Registros duplicados:{" "}
                      <span className="fw-bold">{duplicates.size}</span>
                    </p>
                    <p>
                      Total Importados:{" "}
                      <span className="fw-bold">
                        {dataCSV.length -
                          invalidRecords.length -
                          duplicateCount}
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {step === 1 && (
                <div className="d-flex justify-content-end mt-5">
                  <button
                    className="btn btn-secondary me-3 opacity-75"
                    style={{ width: "120px" }}
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ width: "120px" }}
                    onClick={handleNextStep}
                    disabled={!importName || !selectedFileName}
                  >
                    Siguiente
                  </button>
                </div>
              )}

              {step === 2 && (
                <div className="d-flex justify-content-end mt-5">
                  <button
                    className="btn btn-secondary me-3 opacity-75"
                    style={{ width: "120px" }}
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ width: "120px" }}
                    onClick={handleNextStep}
                  >
                    Proceder
                  </button>
                </div>
              )}

              {step === 3 && (
                <div className="d-flex justify-content-center mt-5 mb-4">
                  <button
                    className="btn btn-success"
                    style={{ width: "120px" }}
                    onClick={toggleModal}
                  >
                    Cerrar
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div className="container-fluid">
        <div className="m-1">
          <p>Filtrar por</p>
        </div>
        <div className="d-flex col-12 justify-content-between align-items-center m-1">
          <div className="d-flex">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Nombre"
                value={term}
                onChange={(event) => setTerm(event.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control ms-1"
                placeholder="Creador"
                value={termCreator}
                onChange={(event) => setTermCreator(event.target.value)}
              />
            </div>
            <button
              className="btn btn-primary ms-2 p-2"
              style={{ height: "40px", width: "150px" }}
              onClick={handleClearFilters}
            >
              Borrar filtros
            </button>
          </div>

          <div className="d-flex align-items-center">
            <h5 className="m-1">Items por pagina</h5>
            <select
              value={itemsPerPage}
              className="form-select text-center"
              style={{ width: "100px" }}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
            <span className="ms-1">
              1 - {itemsPerPage} de {datos.length}
            </span>
            <button className="btn">
              <i className="material-icons fs-3" onClick={handlePrevPage}>
                keyboard_arrow_left
              </i>
            </button>
            <button className="btn">
              <i className="material-icons fs-3" onClick={handleNextPage}>
                keyboard_arrow_right
              </i>
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <table
              className="table table-hover shadow-sm p-3  bg-white rounded"
              style={{ width: "100%" }}
            >
              <thead>
                <tr className="bg-white bg-opacity-75 text-justify">
                  <th scope="col">Nombre</th>
                  <th scope="col">Audiencia</th>
                  <th scope="col">Creador</th>

                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, i) => {
                  return (
                    <tr className="table text-justify" key={i}>
                      <td>{item.importName}</td>
                      <td>{item.importedRows}</td>
                      <td>Marcelo Garcia Marquez</td>

                      <td>
                        <i
                          className="material-icons"
                          onClick={() =>
                            eliminarRegistro(item.id, item.importName)
                          }
                        >
                          delete
                        </i>

                        <i className="material-icons">edit</i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <h5 className="m-1">Items por pagina</h5>
          <select
            value={itemsPerPage}
            className="form-select text-center"
            style={{ width: "100px" }}
            onChange={(e) => {
              setItemsPerPage(parseInt(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
          </select>
          <span className="ms-1">
            1 - {itemsPerPage} de {datos.length}
          </span>
          <button className="btn">
            <i className="material-icons fs-3" onClick={handlePrevPage}>
              keyboard_arrow_left
            </i>
          </button>
          <button className="btn">
            <i className="material-icons fs-3" onClick={handleNextPage}>
              keyboard_arrow_right
            </i>
          </button>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default ImportsTable;
