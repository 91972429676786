import "./App.css";
import '../src/styles/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "../src/styles/visuals.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//proteccion
//pages
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Segments from "./pages/Segments";
import Audience from "./pages/Audience";
import Campaigns from "./pages/Campaigns";
import RegisterUser from "./pages/RegisterUser";
import Importations from "./pages/Importations";
import UsserSettings from "./pages/UsserSettings";
import Gestion from "./pages/Gestion";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/imports" element={<Importations />} />
        <Route path="/segments" element={<Segments />} />
        <Route path="/audience" element={<Audience />} />
        <Route path="/campaigns" element={<Campaigns />}  />
        <Route path="/Register-User" element={<RegisterUser />} />
        <Route path="/gestion" element={<Gestion />} />
        <Route path="/userSettings" element={<UsserSettings />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
