import React from 'react'
import Nav from '../components/Nav'
import CampaignsTable from '../components/CampaignsTable'

function Campaigns() {
  return (
    <div>
        <Nav />
        <div className='container-fluid'>
        <CampaignsTable />
        </div>
    </div>

  )
}

export default Campaigns
