import React from "react";
import Nav from "../components/Nav";
import ImportsTable from "../components/ImportsTable";

function Importations() {
  return (
    <div>
      <Nav />

      <div className="container-fluid">
        <ImportsTable />
      </div>
    </div>
  );
}

export default Importations;
