import React from 'react'

function RegisterUser() {
  return (
    <div>

    </div>
  )
}

export default RegisterUser
