import React from "react";

function Table() {

  const datos = [
    {
      Codigo: "5480",
      Nombre: "New Hires Agosto",
      Audiencia: 20,
      Filtros: "Departamento: Ingenieria, Fecha de ingreso: Agosto 2022",
      Actions: "🗑️ ✏️ 📈 ✉️",
    },
    {
      Codigo: "5480",
      Nombre: "New Hires Noviembre",
      Audiencia: 10,
      Filtros: "Departamento: Legales, Fecha de ingreso: Noviembre 2022",
      Actions: "🗑️ ✏️ 📈 ✉️",
    },
    {
      Codigo: "5480",
      Nombre: "New Hires Octubre",
      Audiencia: 25,
      Filtros: "Departamento: Recursos Humanos, Fecha de ingreso: Octubre 2022",
      Actions: "🗑️ ✏️ 📈 ✉️",
    },
    {
      Codigo: "5480",
      Nombre: "New Hires Enero",
      Audiencia: 45,
      Filtros: "Departamento: Tesoreria, Fecha de ingreso: Enero 2022",
      Actions: "🗑️ ✏️ 📈 ✉️",
    }
  ];

  return (
    <div>
     <div className="row">
              <div className="col">
                <table
                  className="table table-hover shadow-sm p-3  bg-white rounded"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="bg-white bg-opacity-75 text-justify">
                      <th scope="col">Codigo</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Audiencia</th>
                      <th scope="col">Filtros Aplicados</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datos.map((item, i) => {
                      return (
                        <tr className="table text-justify" key={i}>
                          <th scope="row">{item.Codigo}</th>
                          <td>{item.Nombre}</td>
                          <td>{item.Audiencia}</td>
                          <td>{item.Filtros}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
    </div>
  );
}

export default Table;
