import React, {useEffect, useState} from "react";
import Nav from "../components/Nav";
import Charts from "../components/Charts";
import BarCharts from "../components/BarCharts";
import Footer from "../components/Footer";
import Table from "../components/Table";
import AudienceTable from "../components/AudienceTable";
import { Link } from "react-router-dom";
import DonnutChart from "../components/DonnutChart";



function Dashboard() {
  
  
const [user, setUser] = useState(null);


useEffect(() => {
  const fetchData = async () => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      setUser(storedUser);

    }
  };

  fetchData();
}, []);



  return (
    <div>
      <Nav />
      {user && (
      <h2 className="p-3 m-2">Bienvenido {user}</h2>
      )}
      <div className="line"></div>
      <div className="bg-light">
        <h3 className="p-4 text-center fs-2">Resultados últimas campañas</h3>
        <div className="d-flex p-4 justify-content-around align-items-center text-align-center col">
          <div className="charts shadow-sm p-3 mb-5 bg-white rounded">
            <h4>Marzo</h4>
            <Charts />
          </div>
          <div className="donnut shadow-sm p-3 mb-5 bg-white rounded">
            <h4>Abril</h4>
            <DonnutChart />
          </div>
          <div className="charts shadow-sm p-3 mb-5 bg-white rounded">
            <h4>Mayo</h4>
            <BarCharts />
          </div>
        </div>

    <div className="container-fluid">
        <div className="d-flex p-4 justify-content-around align-items-center text-align-center">
          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <Link className="text-decoration-none text-black" to="/segments">
              <h3>Segmentos</h3>
            </Link>
              <Table />
          </div>

          <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <Link to="/audience" className="text-decoration-none text-black">
            <h3>Audiencia</h3>
            </Link>
            <AudienceTable />
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
