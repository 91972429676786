import React from "react";

function AudienceTable() {





  return (
    <div>
      <table className="table table-responsive table-hover">
        <thead>
          <tr className="bg-primary bg-opacity-75 text-white">
            <th scope="col">Status</th>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          <tr className="table">
            <th scope="row">
            <span className="badge rounded-pill bg-success">Suscripto</span>
            </th>
            <td>Mariano</td>
            <td>Santos</td>
            <td>marianosantos@gmail.com</td>
          </tr>
<tr>
           <th scope="row">
             <span className="badge rounded-pill bg-warning">Invitado</span>
           </th>
           <td>Emmanuel</td>
           <td>Oliveira</td>
           <td>emmanuelOliveira@hotmail.com</td>
         </tr>
         <tr className="table">
           <th scope="row">
             <span className="badge rounded-pill bg-success">Suscripto</span>
           </th>
           <td>Alexander</td>
           <td>Armua</td>
           <td>alexander.armua@gmail.com</td>
         </tr>
         <tr className="table">
           <th scope="row">
             <span className="badge rounded-pill bg-success">Suscripto</span>
           </th>
           <td>Miguel</td>
           <td>Jordan</td>
           <td>jumpman23@hotmail.com</td>
         </tr>
         <tr className="table">
           <th scope="row">
             <span className="badge rounded-pill bg-warning">Invitado</span>
           </th>
           <td>Juan</td>
           <td>De Mattos</td>
           <td>juanMattos@gmail.com</td>
         </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AudienceTable;