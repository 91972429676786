import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";
import Nav from "../components/Nav";
import "animate.css";

function Segments() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [term, setTerm] = useState("");
  const [audienceTerm, setAudienceTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [audience, setAudience] = useState([]);
  const [formStep, setFormStep] = useState(1);
  const [segmentName, setSegmentName] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [registroEditado, setRegistroEditado] = useState(null);
  const [nombreSegmentoEditar, setNombreSegmentoEditar] = useState(
    registroEditado ? registroEditado.nombre : ""
  );
 
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  }

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const seleccionarRegistro = (registro) => {
    setSelectedRow(registro);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/audience")
      .then((response) => {
        setAudience(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //Logica Modales

  useEffect(() => {
    // Verificar si hay detalles en registroEditado y establecer selectedItems en consecuencia
    if (registroEditado && registroEditado.detalles) {
      setSelectedItems([...registroEditado.detalles]);
    } else {
      // Si no hay detalles, establecer el estado en un array vacío o algún valor predeterminado
      setSelectedItems([]);
    }
  }, [registroEditado]);

  const handleAddSegment = async (e) => {
    e.preventDefault();
    const segmentData = {
      creador: "Marcelo Garcia Marquez",
      nombre: segmentName,
      audienciaSegmento: selectedItems.length,
      creationTime: Date.now(),
      detalles: selectedItems,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/addSegment",
        segmentData
      );
      console.log(response);
      axios.get(process.env.REACT_APP_API + "/getSegments").then((response) => {
        setData(response.data);
        console.log(response.data);
      });
      toggleModal();
      toast.success("Segmento creado con éxito", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
    } catch (err) {
      console.log("Error al crear al usuario");
    }
  };

  const editarRegistro = (registro) => {
    setRegistroEditado(registro);
    setNombreSegmentoEditar(registro.nombre);
  };

  const handleEdit = async () => {
    const detallesSet = new Set([
      ...registroEditado.detalles,
      ...selectedItems,
    ]);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/updateSegment/${registroEditado.segmentID}`,
        {
          nombre: nombreSegmentoEditar,
          audienciaSegmento: null,
          detalles: Array.from(detallesSet),
        }
      );

      console.log("Registro editado correctamente", response.data);

      axios
        .get(process.env.REACT_APP_API + "/getSegments")
        .then((response) => {
          setData(response.data);
          console.log(response.data);
        })

        .catch((error) => {
          console.error(error);
        });

      setRegistroEditado(null);

      toast.success("Registro editado con éxito", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
    } catch (error) {
      console.error("Error al editar el registro", error);
    }
  };

  const eliminarRegistro = (segmentID, nombre, audienciaSegmento) => {
    Swal.fire({
      title: `Eliminar a ${nombre},  ${audienciaSegmento} registros en este segmento.`,
      text: "Esta acción no se puede deshacer ¿está Seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(process.env.REACT_APP_API + `/deleteSegment/${segmentID}`)
          .then(() => {
            setData(data.filter((item) => item.segmentID !== segmentID));
          })
          .catch((err) => console.log(err));

        toast.success("Registro Eliminado", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });
      }
    });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/getSegments")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const toggleModal = () => {
    setModal(!modal);
    setFormStep(1);
    setSegmentName("");
    setSelectedItems([]);
  };

  const handleNameChange = (e) => {
    setSegmentName(e.target.value);
  };

  const handleChangeStep = () => {
    setFormStep(formStep + 1);
  };

  const goBack = () => {
    setFormStep(formStep - 1);
  };

 const handleRadioChange = (item) => {
  const isSelected = selectedItems.some(
    (selectedItem) => selectedItem.email === item.email
  );

  if (isSelected) {
    // Si ya está seleccionado, quitarlo del array
    const updatedItems = selectedItems.filter(
      (selectedItem) => selectedItem.email !== item.email
    );
    setSelectedItems(updatedItems);
  } else {
    // Si no está seleccionado, agregarlo al array
    setSelectedItems([...selectedItems, item]);
  }
};

  const filteredData = data.filter((item) => {
    const fullName = item.nombre;
    return fullName.toLowerCase().includes(term.toLowerCase());
  });

  const filteredAudience = audience.filter((item) => {
    const nombre = item.name;
    const apellido = item.lastname;
    const email = item.email;

    const term = audienceTerm.toLowerCase();

    return (
      nombre.toLowerCase().includes(term) ||
      apellido.toLowerCase().includes(term) ||
      email.toLowerCase().includes(term)
    );
  });

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;

  const indexOfFirtsItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirtsItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleClearFilters = () => {
    setTerm("");
  };
  const handleClearAudienceFilters = () => {
    setAudienceTerm("");
  };

  const stateColors = {
    suscripto: "bg-success",
    invitado: "bg-warning",
    pendiente: "pendiente",
    rechazado: "bg-danger",
    aprobado: "bg-success",
    nuevo: "nuevo",
    ingresado: "ingresado",
    validar: "validar",
    informado: "informado",
  };

  return (
    <div>
      <Nav />
      {modal && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-75 bg-light p-4 rounded animate__animated animate__fadeIn">
              <h5 className="fw-bold">Nuevo Segmento</h5>
              <hr />

              {formStep === 1 && (
                <>
                  <div className="d-flex ">
                    <label>Nombre de Segmento</label>
                    <input
                      type="text"
                      className="form-control w-25 mb-2 ms-3"
                      value={segmentName}
                      onChange={handleNameChange}
                    />
                  </div>
                  <p className="fw-bold">Filtrar por</p>
                  <div className="d-flex mb-1">
                    <input
                      className="form-control w-25 me-3"
                      type="text"
                      placeholder="Nombre, Apellido, Email"
                      value={audienceTerm}
                      onChange={(event) => setAudienceTerm(event.target.value)}
                    />
                    <select className="form-select w-25 me-3" type="text">
                      <option value="Todos">Todos</option>
                      <option value="últimos 30 días">últimos 30 días</option>
                      <option value="últimos 2 meses">últimos 2 meses</option>
                      <option value="últimos 3 meses">últimos 3 meses</option>
                      <option value="últimos 6 meses">últimos 6 meses</option>
                      <option value="últimos 12 meses">últimos 12 meses</option>
                    </select>

                    <button
                      className="btn btn-primary"
                      onClick={handleClearAudienceFilters}
                    >
                      Borrar filtros
                    </button>
                  </div>
                  <div
                    className="table-container"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    <table
                      className="table table-hover shadow-sm p-3 bg-white rounded"
                      style={{ width: "100%", minHeight: "280px" }}
                    >
                      <thead>
                        <tr className="bg-white bg-opacity-75 text-justify">
                          <th scope="col"></th>
                          <th scope="col">Status</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Apellido</th>
                          <th scope="col">Email</th>
                          <th scope="col">Area</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredAudience.map((item, i) => {
                          return (
                            <tr className="table text-justify" key={i}>
                              <th scope="row">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={selectedItems.some(
                                    (selectedItem) =>
                                      selectedItem.email === item.email
                                  )}
                                  onChange={() => handleRadioChange(item)}
                                />
                              </th>
                              <td>
                                <span
                                  className={`badge rounded-pill ${
                                    stateColors[item.status] || "abandonado"
                                  }`}
                                >
                                  {capitalize(item.status)}
                                </span>
                              </td>
                              <td>{capitalize(item.name)}</td>
                              <td>{item.lastname}</td>
                              <td>{item.email}</td>
                              <td>{item.area ? item.area : "-"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn btn-secondary opacity-75 me-1"
                      onClick={toggleModal}
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleChangeStep}
                      disabled={
                        selectedItems.length === 0 || segmentName === ""
                      }
                    >
                      Siguiente
                    </button>
                  </div>
                </>
              )}

              {formStep === 2 && (
                <>
                  <p>
                    Estás a punto de crear un nuevo segmento, por favor revisá
                    los datos ingresados para confirmar.
                  </p>
                  <p>
                    Nombre del segmento:{" "}
                    <span className="fw-bold">{capitalize(segmentName)}</span>
                  </p>
                  <p>
                    Miembros:{" "}
                    <span className="fw-bold">{selectedItems.length}</span>
                  </p>
                  <div
                    className="table-container"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    <table
                      className="table table-hover shadow-sm p-3 bg-white rounded"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr className="bg-white bg-opacity-75 text-justify">
                          <th scope="col">Status</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Apellido</th>
                          <th scope="col">Email</th>
                          <th scope="col">Area</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedItems.map((item, i) => {
                          return (
                            <tr className="table text-justify" key={i}>
                              <td>
                                <span
                                  className={`badge rounded-pill ${
                                    stateColors[item.status] || "abandonado"
                                  }`}
                                >
                                  {capitalize(item.status)}
                                </span>
                              </td>
                              <td>{item.name}</td>
                              <td>{item.lastname}</td>
                              <td>{item.email}</td>
                              <td>{item.area ? item.area : "-"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mt-1">
                    <button
                      className="btn btn-warning opacity-75 me-1"
                      onClick={goBack}
                    >
                      Retroceder
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleAddSegment}
                    >
                      Finalizar
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {selectedRow && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-75 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <div className="d-flex justify-content-between">
                  <h5 className="fw-bold">
                    Segmento {capitalize(selectedRow.nombre)} (
                    {selectedRow.audienciaSegmento})
                  </h5>
                  <div className="d-flex align-items-center">
                    <i className="material-icons  fw-bold me-1">mail</i>{" "}
                    <span className="fw-bold">(0)</span>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <hr />

                  <table
                    className="table table-hover shadow-sm p-3 bg-white rounded"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr className="bg-white bg-opacity-75 text-justify">
                        <th scope="col">Status</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellido</th>
                        <th scope="col">Email</th>
                        <th scope="col">Area</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedRow.detalles.map((item, i) => {
                        return (
                          <tr className="table text-justify" key={i}>
                            <td>
                              <span
                                className={`badge rounded-pill ${
                                  stateColors[item.status] || "abandonado"
                                }`}
                              >
                                {capitalize(item.status)}
                              </span>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.lastname}</td>
                            <td>{item.email}</td>
                            <td>{item.area ? item.area : "-"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-secondary opacity-75"
                    onClick={() => setSelectedRow(null)}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {registroEditado && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-75 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <div className="d-flex justify-content-between">
                  <h5 className="fw-bold">Editar Segmento</h5>
                  <div className="d-flex align-items-center">
                    <i className="material-icons  fw-bold me-1">mail</i>{" "}
                    <span className="fw-bold">(0)</span>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <div className="d-flex align-items-center">
                    <label className="me-2 fw-bold">Nombre Segmento</label>
                    <input
                      type="text"
                      className="form-control w-25"
                      value={capitalize(nombreSegmentoEditar)}
                      onChange={(e) => setNombreSegmentoEditar(e.target.value)}
                    />
                  </div>
                  <table
                    className="table table-hover shadow-sm p-3 bg-white rounded"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr className="bg-white bg-opacity-75 text-justify">
                        <th scope="col"></th>
                        <th scope="col">Status</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellido</th>
                        <th scope="col">Email</th>
                        <th scope="col">Area</th>
                      </tr>
                    </thead>
                    <tbody>
                      {audience.map((item, i) => {
                        const isSelected =
                          selectedItems.some(
                            (selectedItem) => selectedItem.email === item.email
                          ) ||
                          registroEditado.detalles.some(
                            (detalle) => detalle.email === item.email
                          );

                        return (
                          <tr className="table text-justify" key={i}>
                            <th scope="row">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => handleRadioChange(item)}
                              />
                            </th>
                            <td>
                              <span
                                className={`badge rounded-pill ${
                                  stateColors[item.status] || "abandonado"
                                }`}
                              >
                                {capitalize(item.status)}
                              </span>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.lastname}</td>
                            <td>{item.email}</td>
                            <td>{item.area ? item.area : "-"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-secondary opacity-75 me-2"
                    onClick={() => setRegistroEditado(null)}
                  >
                    Cerrar
                  </button>
                  <button className="btn btn-primary" onClick={handleEdit}>
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container-fluid">
        <div className="container-fluid">
          <div className="d-flex m-2 mt-4 p-2 justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <h3 className="fs-2">Segmentos</h3>
              <p
                className="bg-primary fs-5 text-white text-center ms-1 mb-0 animate__animated animate__fadeIn"
                style={{ width: "40px" }}
              >
                {data.length}
              </p>
            </div>
            <button
              className="fs-2 bg-primary text-white rounded-circle border-0"
              style={{ height: "50px", width: "50px" }}
              onClick={toggleModal}
            >
              +
            </button>
          </div>

          <div className="container-fluid">
            <div className="m-1">
              <p>Filtrar por</p>
            </div>
            <div className="d-flex justify-content-between col-12 align-items-center mt-0">
              <div className="d-flex align-items-center">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control ms-1"
                    placeholder="Creador"
                    value={term}
                    onChange={(event) => setTerm(event.target.value)}
                  />
                </div>
                <button
                  className="btn btn-primary ms-2 p-2"
                  onClick={handleClearFilters}
                  style={{ height: "40px", width: "150px" }}
                >
                  Borrar filtros
                </button>
              </div>
              <div className="d-flex align-items-center">
                <h5 className="m-1">Items por pagina</h5>
                <select
                  value={itemsPerPage}
                  className="form-select text-center"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <span className="ms-1">
                  1 - {data.length} de {data.length}
                </span>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handlePrevPage}>
                    keyboard_arrow_left
                  </i>
                </button>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handleNextPage}>
                    keyboard_arrow_right
                  </i>
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <table
                  className="table table-hover shadow-sm p-3  bg-white rounded"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="bg-white bg-opacity-75 text-justify">
                      <th scope="col">Nombre</th>
                      <th scope="col">Audiencia</th>
                      <th scope="col">Fecha de creación</th>
                      <th scope="col">Creador</th>
                      <th scope="col" className="text-end">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, i) => {
                      return (
                        <tr
                          className="table text-justify"
                          key={i}
                          style={{ cursor: "pointer" }}
                        >
                          <th
                            scope="row"
                            onClick={() => seleccionarRegistro(item)}
                          >
                            {item.nombre}
                          </th>
                          <td onClick={() => seleccionarRegistro(item)}>
                            {item.audienciaSegmento}
                          </td>
                          <td onClick={() => seleccionarRegistro(item)}>
                            {formatDate(item.creationTime)}
                          </td>
                          <td onClick={() => seleccionarRegistro(item)}>{item.creador}</td>
                          <td className="text-end">
                            {" "}
                            <i
                              className="material-icons"
                              onClick={() =>
                                eliminarRegistro(
                                  item.segmentID,
                                  item.nombre,
                                  item.audienciaSegmento
                                )
                              }
                            >
                              delete
                            </i>
                            <i
                              className="material-icons"
                              onClick={() => editarRegistro(item)}
                            >
                              edit
                            </i>
                            <i className="material-icons">mail</i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <h5 className="m-1">Items por pagina</h5>
              <select
                value={itemsPerPage}
                className="form-select text-center"
                style={{ width: "100px" }}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <span className="ms-1">
                1 - {itemsPerPage} de {data.length}
              </span>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handlePrevPage}>
                  keyboard_arrow_left
                </i>
              </button>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handleNextPage}>
                  keyboard_arrow_right
                </i>
              </button>
            </div>
          </div>
          <Toaster position="top-right" reverseOrder={false} />
        </div>
      </div>
    </div>
  );
}

export default Segments;
