import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function Charts() {
  const meses = [
    "Enero",
    "Feb",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const beneficios = [50, 50, 60, 20, 15, 25, 78, 96, 58, 114, 20, 54, 180];
  const marzo = [10, 56, 45, 8, 89, 3, 7, 84, 78, 160, 4, 1];
  const no = [2, 5, 6, 12, 25, 30, 45, 60, 40, 7, 9, 12];

  const myData = {
    labels: meses,
    datasets: [
      {
        label: "Suscriptos",
        data: beneficios,
        tension: 0.5,
        fill: true,
        borderColor: "#660ebf",
        backgroundColor: "rgba(116, 33, 231, 0.5)", // Opacidad ajustada a 0.5
        pointRadius: 6,
      },
      {
        label: "Invitados",
        data: marzo,
        tension: 0.5,
        fill: true,
        borderColor: "#4e7cd0",
        backgroundColor: "rgba(55, 131, 224, 0.5)", // Opacidad ajustada a 0.5
        pointRadius: 6,
      },
      {
        label: "No Suscriptos",
        data: no,
        tension: 0.5,
        fill: true,
        borderColor: "#e63395",
        backgroundColor: "rgba(255, 100, 209, 0.6)", // Opacidad ajustada a 0.5
        pointRadius: 6,
      },
    ],
  };

  const myoptions = {};

  return (
    <div>
      <Line data={myData} options={myoptions} />
    </div>
  );
}

export default Charts;
