import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ["Sin respuesta"],
  datasets: [
    {
      data: ["12", "32"],
      backgroundColor: ["#252525", "#BFBFBF"],
      hoverOffset: 0,
    },
  ],
};

const data2 = {
  labels: ["Parcialmente Interesado"],
  datasets: [
    {
      data: ["06", "32"],
      backgroundColor: ["#ce4722", "#BFBFBF"],
      hoverOffset: 0,
    },
  ],
};

const data3 = {
  labels: ["Total"],
  datasets: [
    {
      label: "Correos",
      data: ["32"],
      backgroundColor: ["#208020", "#288020"],
      hoverOffset: 0,
    },
  ],
};

const data4 = {
  labels: ["Registro Abandonado"],
  datasets: [
    {
      data: ["02", "32"],
      backgroundColor: ["#CEA622", "#BFBFBF"],
      hoverOffset: 0,
    },
  ],
};
const data5 = {
  labels: ["Registro Aprobado"],
  datasets: [
    {
      data: ["06", "32"],
      backgroundColor: ["#09ff00", "#BFBFBF"],
      hoverOffset: 0,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      enabled: true,
    },
    doughnutLabel: {
      labels: [
        {
          render: "label",
          position: "outside",
          fontSize: 16,
          fontStyle: "bold",
          fontColor: "#000000",
        },
        {
          render: "percentage",
          fontColor: "#000000",
          fontSize: 16,
          fontStyle: "bold",
        },
      ],
    },
  },
};

const textCenter = {
  id: "textCenter",
  beforeDatasetsDraw(chart, args, pluginOpions) {
    const { ctx, data } = chart;

    ctx.save();
    ctx.font = "bold 25px sans-serif";
    ctx.fillStyle = "#252525";
    ctx.textAlign = "center";
    ctx.textBaseLine = "middle";
    ctx.fillText(
      data.datasets[0].data[0],
      chart.getDatasetMeta(0).data[0].x,
      chart.getDatasetMeta(0).data[0].y
    );
  },
};

function DonnutCampaign() {
  return (
    <div className="container-fluid">
      <div className="d-flex flex-row">
        <Doughnut data={data3} options={options} plugins={[textCenter]} />
        <Doughnut data={data} options={options} plugins={[textCenter]} />
        <Doughnut data={data2} options={options} plugins={[textCenter]} />
        <Doughnut data={data4} options={options} plugins={[textCenter]} />
        <Doughnut data={data5} options={options} plugins={[textCenter]} />
      </div>
    </div>
  );
}

export default DonnutCampaign;
