import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import navLogo from "../assets/navLogo.png";

function Nav() {
  const location = useLocation();


  const [user, setUser] = useState(null);

  useEffect(() => {
    // Verificar si hay un usuario almacenado en localStorage al cargar la aplicación
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-dark"
        style={{ height: "4rem", backgroundColor: "#123f8d" }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand ms-4">
            <img
              src={navLogo}
              alt="logo"
              style={{ width: "120px", height: "10%" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarColor01"
            aria-controls="navbarColor01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav me-auto ms-auto">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/dashboard" ? "active" : ""
                  }`}
                  to="/dashboard"
                >
                  Dashboard
                  <span className="visually-hidden">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/imports"
                  className={`nav-link ${
                    location.pathname === "/imports" ? "active" : ""
                  }`}
                >
                  Importaciones
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/audience" ? "active" : ""
                  }`}
                  to="/audience"
                >
                  Audiencia
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/segments" ? "active" : ""
                  }`}
                  to="/segments"
                >
                  Segmentos
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/campaigns" ? "active" : ""
                  }`}
                  to="/campaigns"
                >
                  Campañas
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/gestion" ? "active" : ""
                  }`}
                  to="/gestion"
                >
                  Gestion
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav d-flex align-items-center justify-content-center p-3">
              <li className="nav-item" style={{ marginRight: "6px" }}>
                <img
                  className="border-rounded"
                  src="https://ocupacol.mintrabajo.gov.co/img/Profile/Avatars/trabajador_generico.svg"
                  alt="avatar"
                  style={{ height: "2.5rem", width: "50px" }}
                />
              </li>

              {user && (
                <li className="nav-item text-white">
                  <Link
                    className="text-white text-decoration-none"
                    to="/userSettings"
                  >
                    {user}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
