import React from "react";
import { useNavigate } from "react-router-dom";
import loginImg from "../assets/loginLogo.png";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const Home = () => {
  const notify = (message, type) => {
    toast[type](message);
  };

  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassWord] = useState("");

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault(); // Llama a preventDefault al principio de la función

    try {
      const response = await axios.post(process.env.REACT_APP_API + "/login", {
        usuario: userName,
        password: password,
      });

      localStorage.setItem("user", JSON.stringify(response.data.username));
      localStorage.setItem("nombre", JSON.stringify(response.data.nombre));
      localStorage.setItem("apellido", JSON.stringify(response.data.apellido))
      navigate("/dashboard");
      console.log(response)
    } catch (error) {
      console.error("Error al iniciar sesión:", error.response.data.error);
      notify("Usuario o contraseña incorrecto", "error");
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setShowResetPasswordForm(true);
  };

  const handleChangeResetPasswordEmail = (e) => {
    setResetPasswordEmail(e.target.value);
  };

  const handleContinueResetPassword = (e) => {
    e.preventDefault();

    const user = userName.find(
      (M) => M.userName.toLowerCase() === resetPasswordEmail.toLowerCase()
    );

    if (user) {
      notify(
        "Se ha enviado un correo electrónico para restablecer la contraseña",
        "success"
      );
      setShowResetPasswordForm(false);
    } else {
      notify("El correo electrónico no corresponde a ningún usuario", "error");
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center container vh-100 mw-100 bg-opacity-75 bgLogin">
      <div className="border bg-white col-md-3 m-5">
        <form className="p-5">
          <img
            className="mb-0 mx-auto d-block"
            src={loginImg}
            alt="SyngentaLogo"
            style={{ width: "230px", height: "80px" }}
          />
          <h2 className="fs-4 p-2 m-1 text-center fw-bold">Digital Pension</h2>

          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              type="email"
              className="form-control w-100"
              name="username"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Contraseña</label>
            <input
              value={password}
              onChange={(e) => setPassWord(e.target.value)}
              type="password"
              className="form-control w-sm"
              name="password"
              required
            />
          </div>
          <div className="mb-3 form-check">
            <input type="checkbox" className="form-check-input" />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Recordarme
            </label>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn  text-white"
              style={{ backgroundColor: "#123f8d", width: "150px" }}
              onClick={handleLogin}
            >
              Ingresar
            </button>
          </div>
          <div className="text-end mt-5">
            {!showResetPasswordForm && (
              <p style={{ cursor: "pointer" }} onClick={handleResetPassword}>
                Necesito ayuda para ingresar
              </p>
            )}
          </div>

          {showResetPasswordForm && (
            <div>
              <p>Ingrese su email para restablecer su contraseña</p>
              <div className="mb-3">
                <input
                  value={resetPasswordEmail}
                  onChange={handleChangeResetPasswordEmail}
                  type="email"
                  className="form-control w-100"
                  name="resetPasswordEmail"
                  required
                />
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn text-white"
                  style={{ backgroundColor: "#123f8d", width: "150px" }}
                  onClick={handleContinueResetPassword}
                >
                  Continuar
                </button>
              </div>
            </div>
          )}
        </form>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            success: {
              style: {
                background: "green",
                color: "white",
              },
            },
            error: {
              iconTheme: {
                primary: "white",
                secondary: "red",
              },
              style: {
                background: "red",
                color: "#fff",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Home;
