import React from 'react'

function UsserPanel() {
  return (
    <div className='container-fluid'>
        <h3 className='fs-2 mt-4'>Administración de cuenta</h3>

        <div className='row'>
          <div className='d-flex justify-content-around mt-4'>
          <div className='col-xl-4'>
            <div className='shadow-sm p-3 mb-5 bg-white rounded'>
                <h5 className='m-1'>Cambiar contraseña</h5>
                <form>
                      <label className='fs-6 m-1'>Contraseña Actual</label>
                      <br/>
                      <input type='text' className='form-control w-75' />
                      <label className='fs-6 m-1'>Contraseña Nueva</label>
                      <input type='text' className='form-control w-75' />
                      <div className='d-flex justify-content-end m-2 p-1'>
                        <button className='btn btn-primary'>Enviar</button>
                      </div>
                </form>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='shadow-sm p-3 mb-5 bg-white rounded'>
                <h5 className='m-1'>Cambiar Avatar</h5>
                <form>
                      <label className='fs-6 m-1'>Nuevo Avatar</label>
                      <input type='file' className='form-control w-75 m-2' />
                      <div className='d-flex justify-content-end m-2 p-1'>
                        <button className='btn btn-primary'>Enviar</button>
                      </div>
                </form>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default UsserPanel
