import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";
import { RotateLoader } from "react-spinners";

function Locals() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [areaFiltro, setAreaFiltro] = useState("areas");
  const [term, setTerm] = useState("");
  const [termCreator, setTermCreator] = useState("");
  const [modal, setModal] = useState(false);
  const [dataZip, setDataZip] = useState([]);
  const [loading, setLoading] = useState(true);
  const [zipCode, setZipCode] = useState();
  const [localidad, setLocalidad] = useState("");
  const [provincia, setProvincia] = useState("Capital Federal");
  const [registroEditado, setRegistroEditado] = useState(null);
  const [cp, setCP] = useState(registroEditado ? registroEditado.cp : "");
  const [localidadEditar, setLocalidadEditar] = useState(
    registroEditado ? registroEditado.location : ""
  );
  const [provinciaEditar, setProvinciaEditar] = useState(
    registroEditado ? registroEditado.province : ""
  );

  const editarRegistro = (registro) => {
    setRegistroEditado(registro);
    setCP(registro.cp);
    setLocalidadEditar(registro.location);
    setProvinciaEditar(registro.province);
  };

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_GEO}/update/${registroEditado.id}`,
        {
          cp: cp,
          location: localidadEditar,
          province: provinciaEditar,
        }
      );

      console.log("Registro editado correctamente", response.data);

      axios
        .get(process.env.REACT_APP_GEO + "/allZip")
        .then((response) => {
          setDataZip(response.data);
          console.log(response.data);
          setLoading(false);
        })

        .catch((error) => {
          console.error(error);
        });

      setRegistroEditado(null);

      toast.success("Registro creado con éxito", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
    } catch (error) {
      console.error("Error al editar el registro", error);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
    setZipCode();
    setLocalidad("");
    setProvincia("");
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_GEO + "/allZip")
      .then((response) => {
        setDataZip(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const provincias = [
    {
      id: 1,
      nombre: "Capital Federal",
    },
    {
      id: 2,
      nombre: "Buenos Aires",
    },
    {
      id: 3,
      nombre: "Catamarca",
    },
    {
      id: 4,
      nombre: "Chaco",
    },
    {
      id: 5,
      nombre: "Chubut",
    },
    {
      id: 6,
      nombre: "Córdoba",
    },
    {
      id: 7,
      nombre: "Corrientes",
    },
    {
      id: 8,
      nombre: "Entre Ríos",
    },
    {
      id: 9,
      nombre: "Formosa",
    },
    {
      id: 10,
      nombre: "Jujuy",
    },
    {
      id: 11,
      nombre: "La Pampa",
    },
    {
      id: 12,
      nombre: "La Rioja",
    },
    {
      id: 13,
      nombre: "Mendoza",
    },
    {
      id: 14,
      nombre: "Misiones",
    },
    {
      id: 15,
      nombre: "Neuquén",
    },
    {
      id: 16,
      nombre: "Río Negro",
    },
    {
      id: 17,
      nombre: "Salta",
    },
    {
      id: 18,
      nombre: "San Juan",
    },
    {
      id: 19,
      nombre: "San Luis",
    },
    {
      id: 20,
      nombre: "Santa Cruz",
    },
    {
      id: 21,
      nombre: "Santa Fe",
    },
    {
      id: 22,
      nombre: "Santiago del Estero",
    },
    {
      id: 23,
      nombre: "Tierra del Fuego",
    },
    {
      id: 24,
      nombre: "Tucumán",
    },
  ];

  const filteredData = dataZip
    .filter((item) => {
      if (item.cp !== null) {
        const cpString = item.cp.toString();
        return cpString.includes(term);
      }
      return false;
    })
    .filter((item) => {
      const localidad = item.location;
      return localidad.toLowerCase().includes(termCreator.toLowerCase());
    })

    .filter((item) => {
      if (areaFiltro === "areas") {
        return true;
      } else {
        return item.province === areaFiltro;
      }
    });
  const handleChangeFilter = (event) => {
    setAreaFiltro(event.target.value);
  };

  const handleClearFilters = () => {
    setTerm("");
    setTermCreator("");
    setAreaFiltro("areas");
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;

  const indexOfFirtsItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirtsItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const agregarRegistro = () => {
    const data = {
      cp: zipCode,
      province: provincia,
      location: localidad,
    };

    axios
      .post(process.env.REACT_APP_GEO + "/addZipCode", data)
      .then((response) => {
        console.log(response.data);

        axios
          .get(process.env.REACT_APP_GEO + "/allZip")
          .then((response) => {
            setDataZip(response.data);
            console.log(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
        toggleModal();

        toast.success("Registro creado con éxito", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const eliminarRegistro = (id, cp, location) => {
    Swal.fire({
      title: `Eliminar a ${cp}  ${location}`,
      text: "Esta acción no se puede deshacer ¿está Seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(process.env.REACT_APP_GEO + `/deleteZip/${id}`)
          .then(() => {
            setDataZip(dataZip.filter((item) => item.id !== id));
          })
          .catch((err) => console.log(err));

        toast.success("Registro Eliminado", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });
      }
    });
  };

  return (
    <div>
      {modal && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-50 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Nueva Localidad</h5>
                <hr />
                <div className="d-flex flex-column">
                  <form>
                    <div className="d-flex align-items-center">
                      <label className="fw-bold me-2">Cód. Postal</label>
                      <input
                        type="number"
                        className="form-control w-25"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Localidad</label>
                      <input
                        type="text"
                        className="form-control w-50"
                        value={localidad}
                        onChange={(e) => setLocalidad(e.target.value)}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Provincia</label>
                      <select
                        className="form-select w-50"
                        value={provincia}
                        onChange={(e) => setProvincia(e.target.value)}
                      >
                        {provincias.map((provincia) => (
                          <option key={provincia.id} value={provincia.nombre}>
                            {provincia.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={toggleModal}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-primary ms-1"
                      disabled={!zipCode || !localidad}
                      onClick={agregarRegistro}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {registroEditado && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-50 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Editar Localidad</h5>
                <hr />
                <div className="d-flex flex-column">
                  <form>
                    <div className="d-flex align-items-center">
                      <label className="fw-bold me-2">Cód. Postal</label>
                      <input
                        type="text"
                        className="form-control w-25"
                        value={cp}
                        onChange={(e) => setCP(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Localidad</label>
                      <input
                        type="text"
                        className="form-control w-50"
                        value={localidadEditar}
                        onChange={(e) => setLocalidadEditar(e.target.value)}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Provincia</label>
                      <select
                        className="form-select w-50"
                        value={provinciaEditar}
                        onChange={(e) => setProvinciaEditar(e.target.value)}
                      >
                        {provincias.map((provincia) => (
                          <option key={provincia.id} value={provincia.nombre}>
                            {provincia.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={() => setRegistroEditado(null)}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-primary ms-1"
                      onClick={handleEdit}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        {loading ? (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <RotateLoader color="#123f8d" />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="d-flex m-2 mt-4 p-2 justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <h3 className="fs-2">Localidades</h3>
                <p
                  className="bg-primary fs-5 text-white text-center ms-1  mb-0 animate__animated animate__fadeIn"
                  style={{ width: "60px" }}
                >
                  {dataZip.length}
                </p>
              </div>
              <button
                className="fs-2 bg-primary text-white rounded-circle border-0"
                style={{ height: "50px", width: "50px" }}
                onClick={toggleModal}
              >
                +
              </button>
            </div>

            <div className="container-fluid">
              <div className="m-1">
                <p>Filtrar por</p>
              </div>

              <div className="d-flex col-12 justify-content-between align-items-center m-1">
                <div className="d-flex">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Código Postal"
                      value={term}
                      onChange={(event) => setTerm(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control ms-1"
                      placeholder="Localidad"
                      value={termCreator}
                      onChange={(event) => setTermCreator(event.target.value)}
                    />
                  </div>
                  <select
                    className="form-select ms-2"
                    style={{ width: "200px", height: "38px" }}
                    value={areaFiltro}
                    onChange={handleChangeFilter}
                  >
                    <option value="todos">Provincia</option>
                    <option value="Capital Federal">Capital Federal</option>
                    <option value="Buenos Aires">Buenos Aires</option>
                    <option value="Catamarca">Catamarca</option>
                    <option value="Chaco">Chaco</option>
                    <option value="Chubut">Chubut</option>
                    <option value="Cordoba">Cordoba</option>
                    <option value="Corrientes">Corrientes</option>
                    <option value="Entre Rios">Entre Ríos</option>
                    <option value="Formosa">Formosa</option>
                    <option value="Jujuy">Jujuy</option>
                    <option value="La Pampa">La Pampa</option>
                    <option value="La Rioja">La Rioja</option>
                    <option value="Mendoza">Mendoza</option>
                    <option value="Misiones">Misiones</option>
                    <option value="Neuquen">Neuquén</option>
                    <option value="Rio Negro">Río Negro</option>
                    <option value="Salta">Salta</option>
                    <option value="San Juan">San Juan</option>
                    <option value="San Luis">San Luis</option>
                    <option value="Santa Cruz">Santa Cruz</option>
                    <option value="Santa Fe">Santa Fe</option>
                    <option value="Santiago Del Estero">
                      Santiago del Estero
                    </option>
                    <option value="Tierra Del Fuego">Tierra del Fuego</option>
                    <option value="Tucuman">Tucuman</option>
                  </select>
                  <button
                    className="btn btn-primary ms-1 p-2"
                    style={{ height: "40px", width: "150px" }}
                    onClick={handleClearFilters}
                  >
                    Borrar filtros
                  </button>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <h5 className="m-1">Items por pagina</h5>
                  <select
                    value={itemsPerPage}
                    className="form-select text-center"
                    style={{ width: "100px" }}
                    onChange={(e) => {
                      setItemsPerPage(parseInt(e.target.value));
                      setCurrentPage(1);
                    }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  {currentPage === 1 ? (
                    <span className="ms-1">
                      1 -{" "}
                      {itemsPerPage < filteredData.length
                        ? itemsPerPage
                        : filteredData.length}{" "}
                      de {filteredData.length}
                    </span>
                  ) : (
                    <span className="ms-1">
                      {itemsPerPage + 1} - {filteredData.length} de{" "}
                      {filteredData.length}
                    </span>
                  )}
                  <button className="btn">
                    <i className="material-icons fs-3" onClick={handlePrevPage}>
                      keyboard_arrow_left
                    </i>
                  </button>
                  <button className="btn">
                    <i className="material-icons fs-3" onClick={handleNextPage}>
                      keyboard_arrow_right
                    </i>
                  </button>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <table
                    className="table table-hover shadow-sm p-3  bg-white rounded"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr className="bg-white bg-opacity-75 text-justify">
                        <th scope="col">Codigo Postal</th>
                        <th scope="col">Localidad</th>
                        <th scope="col">Provincia</th>
                        <th scope="col" className="text-end">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, i) => {
                        return (
                          <tr className="table text-justify" key={i}>
                            <th scope="row">{item.cp}</th>
                            <td>{item.location}</td>
                            <td>{item.province}</td>

                            <td className="text-end">
                              <i
                                className="material-icons"
                                onClick={() =>
                                  eliminarRegistro(
                                    item.id,
                                    item.cp,
                                    item.location
                                  )
                                }
                              >
                                delete
                              </i>
                              <i
                                className="material-icons"
                                onClick={() => editarRegistro(item)}
                              >
                                edit
                              </i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <h5 className="m-1">Items por pagina</h5>
                <select
                  value={itemsPerPage}
                  className="form-select text-center"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                {currentPage === 1 ? (
                  <span className="ms-1">
                    1 -{" "}
                    {itemsPerPage < filteredData.length
                      ? itemsPerPage
                      : filteredData.length}{" "}
                    de {filteredData.length}
                  </span>
                ) : (
                  <span className="ms-1">
                    {itemsPerPage + 1} - {filteredData.length} de{" "}
                    {filteredData.length}
                  </span>
                )}
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handlePrevPage}>
                    keyboard_arrow_left
                  </i>
                </button>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handleNextPage}>
                    keyboard_arrow_right
                  </i>
                </button>
              </div>
            </div>
          </div>
        )}
        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </div>
  );
}

export default Locals;
