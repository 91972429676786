import React, { useState } from "react";
import Nav from "../components/Nav";
import Locals from "../components/Locals";
import Apartments from "../components/Apartments";
import Ussers from "../components/Ussers";
import "../styles/i-styles.css"

function Gestion() {
  const [compo, setCompo] = useState("Localidades");
  const handleButtonClick = (selectedCompo) => {
    setCompo(selectedCompo);
  };


  return (
    <div>
      <Nav></Nav>

      <div className="d-flex justify-content-center mt-3 animate__animated animate__fadeIn">
      <button
        className={`btn btn-primary fw-bold me-1 rounded ${compo === "Localidades" ? 'selectedBlue' : ''}`}
        onClick={() => handleButtonClick("Localidades")}
      >
        Localidades
      </button>
      <button
        className={`btn btn-success fw-bold me-1 rounded ${compo === "Departamentos" ? 'selectedGreen' : ''}`}
        onClick={() => handleButtonClick("Departamentos")}
      >
        Departamentos
      </button>
      <button
        className={`btn btn-warning fw-bold rounded ${compo === "Usuarios" ? 'selectedOrange' : ''}`}
        onClick={() => handleButtonClick("Usuarios")}
      >
        Usuarios
      </button>
      </div>

      <div className="container-fluid animate__animated animate__fadeIn">
        {compo === "Localidades" && <Locals />}
        {compo === "Departamentos" && <Apartments />}
        {compo === "Usuarios" && <Ussers />}
      </div>
    </div>
  );
}

export default Gestion;
