import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const labels = [
  "Enero",
  "Feb",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const marzo = [10, 56, 45, 8, 89, 23, 15, 84, 78, 160, 4, 1];
const beneficios = [50, 50, 60, 20, 68, 35, 78, 96, 58, 114, 20, 54, 180];
export const data = {
  labels,
  datasets: [
    {
      label: "Invitados",
      data: marzo,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Suscriptos",
      data: beneficios,
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

function BarCharts() {
  return <Bar options={options} data={data} />;
}

export default BarCharts;
