import React from "react";

function Footer() {
  return (
    <div>
    <footer className="bg-light text-center text-lg-start">
      <div className="text-center p-3 d-flex justify-content-evenly" style={{background: "rgba(0, 0, 0, 0.2)" }}>
        <span>© 2023 Copyright: Syngenta</span>
        <span className="ms-auto"><strong>V-1.3.1</strong></span>
      </div>
    </footer>
  </div>
  );
}

export default Footer;
