import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";

function Ussers() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [term, setTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usuario, setUsuario] = useState("");
  const [registroEditado, setRegistroEditado] = useState(null);
  const [pass, setPass] = useState(null);

  const [passwordEditar, setPasswWordEditar] = useState(
    pass ? pass.password : ""
  );

  const [apellidoEditar, setApellidoEditar] = useState(
    registroEditado ? registroEditado.apellido : ""
  );
  const [nombreEditar, setNombreEditar] = useState(
    registroEditado ? registroEditado.nombre : ""
  );
  const [mailEditar, setEmailEditar] = useState(
    registroEditado ? registroEditado.email : ""
  );

  const [usuarioEditar, setUsuarioEditar] = useState(
    registroEditado ? registroEditado.usuario : ""
  );

  const editarRegistro = (registro) => {
    setRegistroEditado(registro);
    setApellidoEditar(registro.apellido);
    setNombreEditar(registro.nombre);
    setEmailEditar(registro.email);
    setUsuarioEditar(registro.usuario);
  };

  const editPass = (registro) => {
    setPass(registro);
  };

  const toggleModal = () => {
    setModal(!modal);
    setNombre("");
    setApellido("");
    setEmail("");
    setPassword("");
    setUsuario("");
  };

  const handleNombreChange = (e) => {
    setNombre(e.target.value);
  };

  const handleApellidoChange = (e) => {
    setApellido(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUsuarioChange = (e) => {
    setUsuario(e.target.value);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/getUssers")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filteredData = data.filter((item) => {
    const usuario = item.usuario;
    return usuario.toLocaleLowerCase().includes(term);
  });

  const handleClearFilters = () => {
    setTerm("");
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;

  const indexOfFirtsItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirtsItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangePass = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/user/changePassword/${pass.id}`, // Agrega el ID a la URL
        {
          newPassword: passwordEditar,
        }
      );

      console.log("Contraseña cambiada correctamente", response.data);

      // Refresca la lista de usuarios después de cambiar la contraseña
      axios
        .get(process.env.REACT_APP_API + "/getUssers")
        .then((response) => {
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });

      setPass(null);

      toast.success("Contraseña cambiada con éxito", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
    } catch (error) {
      console.error("Error al cambiar la contraseña", error);
    }
  };

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/user/update/${registroEditado.id}`,
        {
          nombre: nombreEditar,
          apellido: apellidoEditar,
          usuario: usuarioEditar,
          email: mailEditar,
        }
      );

      console.log("Registro editado correctamente", response.data);

      axios
        .get(process.env.REACT_APP_API + "/getUssers")
        .then((response) => {
          setData(response.data);
          console.log(response.data);
        })

        .catch((error) => {
          console.error(error);
        });

      setRegistroEditado(null);

      toast.success("Registro editado con éxito", {
        style: {
          padding: "10px",
          background: "#12b155",
          color: "#fff",
          font: "sans-serif",
        },
        iconTheme: {
          primary: "#12b155",
          secondary: "#FFF",
        },
      });
    } catch (error) {
      console.error("Error al editar el registro", error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      nombre,
      apellido,
      email,
      password,
      usuario,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/user/create",
        userData
      );
      console.log(response);
      toast.success("Usuario Creado con exito");
      axios.get(process.env.REACT_APP_API + "/getUssers").then((response) => {
        setData(response.data);
        console.log(response.data);
      });
      toggleModal();
    } catch (error) {
      toast.error("Error al crear al usuario");
    }
  };

  const eliminarRegistro = (id, nombre, apellido) => {
    Swal.fire({
      title: `Eliminar a ${nombre}  ${apellido}`,
      text: "Esta acción no se puede deshacer ¿está Seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(process.env.REACT_APP_API + `/deleteUser/${id}`)
          .then(() => {
            setData(data.filter((item) => item.id !== id));
          })
          .catch((err) => console.log(err));

        toast.success("Registro Eliminado", {
          style: {
            padding: "10px",
            background: "#12b155",
            color: "#fff",
            font: "sans-serif",
          },
          iconTheme: {
            primary: "#12b155",
            secondary: "#FFF",
          },
        });
      }
    });
  };

  return (
    <div>
      {modal && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-25 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Nuevo Usuario</h5>
                <hr />
                <div className="d-flex flex-column">
                  <form>
                    <div className="d-flex align-items-center">
                      <label className="fw-bold me-2">Nombre</label>
                      <input
                        type="text"
                        style={{ marginLeft: "13%" }}
                        className="form-control w-75"
                        value={nombre}
                        onChange={handleNombreChange}
                        required
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Apellido</label>
                      <input
                        type="text"
                        className="form-control w-75"
                        value={apellido}
                        onChange={handleApellidoChange}
                        style={{ marginLeft: "10%" }}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Usuario</label>
                      <input
                        type="text"
                        className="form-control w-75"
                        value={usuario}
                        onChange={handleUsuarioChange}
                        style={{ marginLeft: "10%" }}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Email</label>
                      <input
                        type="email"
                        className="form-control w-75 ms-5"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Constraseña</label>
                      <input
                        type="password"
                        className="form-control w-75"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                  </form>

                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={toggleModal}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-primary ms-1"
                      onClick={handleSubmit}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {registroEditado && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-25 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Editar Localidad</h5>
                <hr />
                <div className="d-flex flex-column">
                  <form>
                    <div className="d-flex align-items-center">
                      <label className="fw-bold me-2">Nombre</label>
                      <input
                        type="text"
                        style={{ marginLeft: "13%" }}
                        className="form-control w-75"
                        value={nombreEditar}
                        onChange={(e) => setNombreEditar(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Apellido</label>
                      <input
                        type="text"
                        className="form-control w-75"
                        value={apellidoEditar}
                        onChange={(e) => setApellidoEditar(e.target.value)}
                        style={{ marginLeft: "10%" }}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Usuario</label>
                      <input
                        type="text"
                        className="form-control w-75"
                        value={usuarioEditar}
                        onChange={(e) => setUsuarioEditar(e.target.value)}
                        style={{ marginLeft: "10%" }}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <label className="fw-bold me-3">Email</label>
                      <input
                        type="email"
                        className="form-control w-75 ms-5"
                        value={mailEditar}
                        onChange={(e) => setEmailEditar(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-secondary opacity-75"
                    onClick={() => setRegistroEditado(null)}
                  >
                    Cerrar
                  </button>
                  <button className="btn btn-primary ms-1" onClick={handleEdit}>
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {pass && (
        <>
          <div className="backdrop">
            <div className="position-absolute top-50 start-50 translate-middle w-25 bg-light p-4 rounded animate__animated animate__fadeIn">
              <div>
                <h5 className="fw-bold">Cambiar Contraseña</h5>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <label className="me-5">Usuario</label>
                    <input
                      className="form-control w-75 mb-2"
                      type="text"
                      value={pass.usuario}
                      readOnly
                      required
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="me-5">Contraseña</label>
                    <input
                      className="form-control w-75"
                      type="text"
                      value={pass.password}
                      readOnly
                      required
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="me-1">Nueva contraseña</label>
                    <input
                      className="form-control w-75"
                      type="text"
                      onChange={(e) => setPasswWordEditar(e.target.value)}
                      required
                    />
                  </div>

                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-secondary opacity-75"
                      onClick={() => setPass(null)}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-primary ms-1"
                      onClick={handleChangePass}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <div className="container-fluid">
          <div className="d-flex m-2 mt-4 p-2 justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <h3 className="fs-2">Usuarios</h3>
              <p
                className="bg-warning fs-5 text-white text-center ms-1  mb-0 animate__animated animate__fadeIn"
                style={{ width: "40px" }}
              >
                {data.length}
              </p>
            </div>
            <button
              className="fs-2 bg-primary text-white rounded-circle border-0"
              style={{ height: "50px", width: "50px" }}
              onClick={toggleModal}
            >
              +
            </button>
          </div>

          <div className="container-fluid">
            <div className="m-1">
              <p>Filtrar por</p>
            </div>

            <div className="d-flex col-12 justify-content-between align-items-center m-1">
              <div className="d-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Usuario"
                    value={term}
                    onChange={(event) => setTerm(event.target.value)}
                  />
                </div>

                <button
                  className="btn btn-primary ms-1 p-2"
                  style={{ height: "40px", width: "150px" }}
                  onClick={handleClearFilters}
                >
                  Borrar filtros
                </button>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <h5 className="m-1">Items por pagina</h5>
                <select
                  value={itemsPerPage}
                  className="form-select text-center"
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                </select>
                <span className="ms-1">
                  1 - {data.length < itemsPerPage ? data.length : itemsPerPage}{" "}
                  de {data.length < itemsPerPage ? data.length : itemsPerPage}
                </span>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handlePrevPage}>
                    keyboard_arrow_left
                  </i>
                </button>
                <button className="btn">
                  <i className="material-icons fs-3" onClick={handleNextPage}>
                    keyboard_arrow_right
                  </i>
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <table
                  className="table table-hover shadow-sm p-3  bg-white rounded"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="bg-white bg-opacity-75 text-justify">
                      <th scope="col">Usuario</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Apellido</th>
                      <th scope="col">Email</th>
                      <th scope="col" className="text-end">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, i) => {
                      return (
                        <tr className="table text-justify" key={i}>
                          <th scope="row">{item.usuario}</th>
                          <td>{item.nombre}</td>
                          <td>{item.apellido}</td>
                          <td>{item.email}</td>

                          <td className="text-end">
                            <i
                              className="material-icons"
                              onClick={() => editPass(item)}
                            >
                              lock_open
                            </i>
                            <i
                              className="material-icons"
                              onClick={() =>
                                eliminarRegistro(
                                  item.id,
                                  item.nombre,
                                  item.apellido,
                                  item.usuario
                                )
                              }
                            >
                              delete
                            </i>
                            <i
                              className="material-icons"
                              onClick={() => editarRegistro(item)}
                            >
                              edit
                            </i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <h5 className="m-1">Items por pagina</h5>
              <select
                value={itemsPerPage}
                className="form-select text-center"
                style={{ width: "100px" }}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
              <span className="ms-1">
                1 - {data.length < itemsPerPage ? data.length : itemsPerPage} de{" "}
                {data.length < itemsPerPage ? data.length : itemsPerPage}
              </span>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handlePrevPage}>
                  keyboard_arrow_left
                </i>
              </button>
              <button className="btn">
                <i className="material-icons fs-3" onClick={handleNextPage}>
                  keyboard_arrow_right
                </i>
              </button>
            </div>
          </div>
        </div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    </div>
  );
}

export default Ussers;
