import React from 'react'
import Nav from '../components/Nav'
import UsserPanel from '../components/UsserPanel'

function UsserSettings() {
  return (
    <div>
      <Nav />
      <div className='container-fluid'>
        <UsserPanel />
      </div>
    </div>
  )
}

export default UsserSettings
