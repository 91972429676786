import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
    labels: [
      'Suscriptos',
      'Invitados'
    ],
    datasets: [{
      label: '',
      data: [150, 50],
      backgroundColor: [
        '#3A6EA5',
        '#fff'
      ], 
    }]
  };


const config = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    }
  }}


function DonnutChart() {
  return (
    <div className='container-fluid'>
    <Doughnut options={config} data={data}></Doughnut>
    </div>
  )
}

export default DonnutChart
